html,
body {
    width: 100%;
    height: 100%;
    text-wrap: pretty;
}

#root {
    height: 100%;
    width: 100%;
}

a {
    transition: 0.15s;
    text-decoration: none;
}

a:hover, a:focus, a:active {
    text-decoration: underline;
}
